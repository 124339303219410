<template>
  <div :class="['messageContainer', isCard && 'messageContainer--inCard']">
    <span class="cardNew__title">Mensaje</span>
    <p class="cardNew__message">{{ message || "Sin mensaje" }}</p>
  </div>
</template>

<script>
export default {
  props: ["message", "isCard"],
};
</script>
<style lang="scss">
.messageContainer {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  padding: 10px;
  gap: 5px;
  font-weight: 500;
  font-size: 13px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  &--inCard {
    min-width: 400px;
    max-height: 150px;
    overflow: auto;
  }
  &__message {
    color: #5a5a5a;
    font-weight: 400;
  }
}
</style>
